
.main-banner {
  &.hero-section-5 {
    padding: 431px 0px 121px 30px;
  }

  h1 {
    font-family: 'SegoeuiRegular';
    margin: 0;
    font-size: 40px;

    &.text-bold {
      font-family: 'SegoeuiBold';
    }

    &.text-blue {
      text-shadow: 0 0px 6px #222
    }
  }

  .btn-lg {
    padding: 4px 50px 8px;
    font-size: 1.4rem;
    font-family: "SegoeuiLight";
  }
}

@media (max-width: 991px) {
  .main-banner {
    &.hero-section-5 {
      padding: 261px 0px 121px 30px;
    }
  }
}